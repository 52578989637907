.header{
    padding:  0;
    position: sticky;
    top: -70px;
    background: var(--theme-surface-color);
    z-index: var(--weight-one);
}


@media (max-width: 768px) {
    .header{
        top: -48px;
    }
}

table{
    width: 100%;
    font-size: var(--size-regular);
    border-spacing: 0 0.5rem;
    table-layout:fixed;
}

.table-top-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.table-wrapper .heading{
    padding: 1rem;
    font-size: var(--size-medium);
    font-weight: bold;
}

tr{
    border-radius: 1rem;
    transition: ease-out visibility 0.2s;
}

tr.selected{
    background-color: var(--theme-primary-container);
}

th{
    text-align: start;
    text-transform: uppercase;
    height: 5rem;
    vertical-align: top !important;
    padding: .75rem .5rem;
    border-bottom: 2px solid var(--theme-outline-color);
    color: var(--theme-muted-color);
}

td{
    padding: .75rem .5rem;
    border-right:none;
}

th:last-child{
    text-align: end;
}

.table-wrapper{
    width: 100%;
    overflow-x: auto;
    padding-bottom: 0.5rem;
}

.table-wrapper .action-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: none;
    height: 100%;
    width: 100%;
    background-color: var(--theme-surface-color);
}

.table-wrapper .action{
    padding: 0.75rem 1rem;
    border-radius: 50px;
    margin-left: 0.5rem;
    background-color: var(--theme-primary-container);
    font-weight: normal;
    cursor: pointer;
    text-transform: none;
    transition: ease all 0.15s;
}

.table-wrapper .action:hover{
    background-color: #000;
    color: var(--theme-surface-color);
}

.table-top-bar .view-toggle{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 1px solid #ddd;
    overflow: hidden;
    margin-left: 1rem;
}

.table-top-bar .view-toggle .icon{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-surface-color);
    border-radius: 50px;
    cursor: pointer;
}

.table-top-bar .view-toggle .icon-active{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
    background-color: var(--theme-primary-container);
}

.table-top-bar .pagination{
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 50px;
    border: 1px solid #ddd;
    overflow: hidden;
}

.table-top-bar .pagination .handle{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50px;
}

.table-top-bar .pagination .handle:hover{
    background-color: var(--theme-primary-container);
}

.table-top-bar .pagination .counter{
    font-size: 0.8rem;
    padding: 0 0.5rem;
    white-space: nowrap;
}

.tile-view{
    display: flex;
    flex-wrap: wrap;
}

.table-top-bar .filters{
    position: relative;
}

.table-top-bar .filters .icon{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-surface-color);
    border-radius: 50px;
    border: 1px solid var(--theme-border-color);
    cursor: pointer;
    font-size: 0.8rem;
}

.table-top-bar .filters .icon-active{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 1px solid var(--theme-border-color);
    cursor: pointer;
    font-size: 0.8rem;
    background-color: var(--theme-primary-container);

}

.table-top-bar .filters .icon:hover{
    background-color: var(--theme-primary-container);
}

.scrollable-table .table-sticky-row-first {
    background: var(--theme-surface-color);
}




@media (min-width:900px) {
    .scrollable-table .table-sticky-row-first {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        z-index: 2;
        top: auto;
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: var(--theme-border-color);
    }

    .scrollable-table .table-sticky-row-second {
        position: -webkit-sticky;
        position: sticky;
        top: auto;
        border-right: 1px solid var(--theme-border-color);
    }

}


.scrollable-table td{
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: var(--theme-border-color);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--theme-border-color);
    padding: 1.25rem;
}

.scrollable-table th{
    padding: .75rem;
    height: 35px;
    font-size: .9rem;
    font-weight: 600;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: var(--theme-border-color);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--theme-border-color);
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--theme-border-color);
}

.scrollable-table tr:hover{
    background: var(--theme-secondary-container);
    color: var(--theme-on-secondary-container);
}
.scrollable-table tr:hover .table-sticky-row-first{
    background: var(--theme-secondary-container);
}


.scrollable-table th:first-child{
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--theme-border-color);
    border-top-left-radius: 4px;
}
.scrollable-table th:last-child{
    padding-right: 0;
    border-top-right-radius: 4px;
}

.scrollable-table tr td:first-child{
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--theme-border-color);
}

.scrollable-table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 4px;
}
.scrollable-table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 4px;
}

.padding-table{
    padding-right: 24px;
}

.borderLess2 .padding-table{
    padding-right: 0;
}

.borderLess2 .scrollable-table th{
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    padding: 1.5rem 0 !important;
    font-size: var(--size-small) !important;
    color: var(--theme-text-color) !important;
}
.borderLess2 .scrollable-table tr td{
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    padding: 1rem 0 !important;
}

button span[aria-label="more"]{
    transform: rotate(90deg);
}


.borderLess2 tbody tr:hover .table-sticky-row-first {
    background: var(--theme-variant-color);
}


.borderLess2 tbody tr:hover{
    background: var(--theme-variant-color) !important;
    color: var(--theme-on-variant-color);
}

.borderTable th{
    font-size: .85rem;
}


.spotlight-wrapper{
    position: fixed;
    z-index:22;
    display: flex;
    justify-content: center;
    align-items:flex-start;
    background-color: rgba(0,0,0,0.4);
    height: 100%;
    width: 100%;
    padding: 5%;
}

.spotlight-wrapper .support-panel{
    background-color: var(--theme-surface-color);
    border: 1px solid var(--theme-border-color);
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 0.3rem 0.8rem rgba(0,0,0,0.3);
    padding: 1rem;
}

.spotlight{
    background-color: var(--theme-surface-color);
    border: 1px solid var(--theme-border-color);
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 0.3rem 0.8rem rgba(0,0,0,0.3);
}

.spotlight .search{
    padding: 0.5rem 1rem;
    border-bottom:  1px solid var(--theme-border-color) !important;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.spotlight .search-box{
    border: none !important;
    border-radius: 0px;
    background-color: var(--theme-surface-color)  !important;;
    width: 500px;
}

.spotlight .results{
    padding: 0rem;
}

.spotlight .results .section{
    padding: 0.75rem 0.5rem;
    border-bottom:  1px solid var(--theme-border-color) !important;
}

.spotlight .results .section .heading{
    font-size: 0.8rem;
    font-weight: normal;
    color: #999;
    margin-bottom: 0.5rem;
    padding-left: 0.5rem;
}

.spotlight .results .section .item{
    display: flex;
    align-items: center;
    padding: 0.75rem;
    border-radius: 0.5rem;
    cursor: pointer;
}

.spotlight .results .section .item:hover{
    background-color: var(--theme-primary-container);
}

.spotlight .results .section .item .title{
    padding-left: 0.75rem;
}

.spotlight .quick-links{
    display: flex;
    align-items: center;
    padding: 1rem;
}

.spotlight .quick-links .link{
    display: flex;
    align-items: center;
    border-radius: 50px;
    padding: 0.5rem 0.75rem;
    margin-right: 0.5rem;
    background-color: #F2f2f2;
    cursor: pointer;
    font-size: 0.8rem;
}

.spotlight .quick-links .link:hover{
    background-color: var(--theme-primary-container);
}

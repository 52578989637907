.support .ticket{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem;
    font-size: 0.8rem;
    border-bottom: 1px solid var(--theme-border-color);
    background-color: #fff;
    cursor: pointer;
    transition: ease all 0.2s;
}

.support .ticket:hover{
    background-color: #f2f2f2;
}

.support .ticket-active{
    border-color: var(--theme-primary-color);
    background-color: var(--theme-primary-color);
    columns: #fff !important;
}

.support .details{
    flex-grow: 1;
}

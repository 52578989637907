.stats-card {
    border: 1px solid var(--theme-border-color);
    border-radius: var(--size-regular);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: space-between;
    margin: var(--size-xsmall);
    padding: var(--size-medium);
    transition: all .2s ease;
    width: 150px;
}

.graph-container{
    width: 100%;
}
@media (min-width:586px) {
    .graph-container{
        width: calc(50% - 2rem);
    }
}
@media (min-width:990px) {
    .graph-container{
        width: calc(50% - 2rem);
    }
}

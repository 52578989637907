.gaugeline{
    position: relative;
    width: 100%;
    height: 2.5rem;
    margin-top: 1rem;
}

.gaugeline .track{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 8px;
    border-radius: 0.5rem;
    background-color: var(--theme-border-color);
}

.gaugeline .fill{
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    border-radius: 0.5rem;
    background-color: var(--theme-primary-color);
    transition: ease-out all 0.4s;
}

.gaugeline .legend{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.8rem;
    position: absolute;
    top: 1.2rem;
    left: 0;
}
.modal-wrapper{
    position: fixed;
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.4);
    height: 100%;
    max-height: 100vh;
    width: 100%;
    padding: 2rem;
    overflow-y: scroll;
}

.modal{
    background-color: var(--theme-surface-color);
    border-radius: 0.25rem;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    min-height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;
}

.modal .content{
    margin: var(--size-medium);
    flex-grow: 1;
}

.modal .top-bar{
    background-color: var(--theme-surface-color);
    width:100%;
    border-radius: 0.25rem;
}

.modal-wrapper .bottom-bar{
    padding: var(--size-medium);
    padding-top: var(--size-small);
}

.modal .title{
    font-size: var(--size-large);
    font-weight: 600;
}

.modal .description{
    color: var(--theme-muted-color);
    font-size: var(--size-regular);
}

@media (max-width: 576px) {
    .modal-wrapper{
        height: 100% !important;
    }

    .modal .top-bar{
        position: sticky;
        top: 0px;
        z-index: 10;
    }

    .modal{
        position: fixed;
        bottom: 0;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: var(--theme-surface-color);
        width: 100%;
        max-width: 100% !important;
        max-width: 100%;
        border-radius: 2rem 2rem 0rem 0rem;
        max-height: 90%;
    }

    .modal .content{
        overflow-y: scroll;
    }

    .modal-wrapper .bottom-bar{
        padding: var(--size-medium);
        padding-top: 0px;
        background-color: var(--theme-surface-color);
        width: 100%;
    }
}

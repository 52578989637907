.approval-card{
    border: 1px solid var(--theme-border-color);
    border-radius: 0.5rem;
    background-color: #fff;
    padding: 1rem;
    margin: 0.5rem;
    cursor: pointer;
    transition: ease all 0.2s;
    width: 100%;
    max-width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.approval-card:hover{
    box-shadow: 0rem 0.2rem 0.4rem rgba(0,0,0,0.1);
    border-color: #fff;
}

@media (max-width: 576px) {
    .approval-card{
        width: 100%;
        max-width: 100% !important;
    }
}
.mail-tile{
    flex-grow: 1;
    padding: 1rem;
    margin-top: 0.5rem;
    font-size: 0.8rem;
    background-color: var(--theme-surface-color);
    border: 1px solid var(--theme-variant-color);
    width: 100%;
    border-radius: 0.7rem;
    cursor: pointer;
    text-align: left;
    transition: ease-in all 0.2s;
}

.mail-tile-unread{
    flex-grow: 1;
    padding: 1rem;
    margin-top: 0.5rem;
    font-size: 0.8rem;
    background-color: var(--theme-primary-container);
    border: 1px solid var(--theme-primary-color);
    width: 100%;
    border-radius: 0.7rem;
    cursor: pointer;
    text-align: left;
    transition: ease-in all 0.2s;
}

.mail-tile-active{
    flex-grow: 1;
    padding: 1rem;
    margin-top: 0.5rem;
    font-size: 0.8rem;
    width: 100%;
    border-radius: 0.7rem;
    cursor: pointer;
    text-align: left;
    background-color: var(--theme-primary-color);
    border: 1px solid var(--theme-primary-color);
    color: #fff;
}

.mail-tile-unread:hover, .mail-tile:hover{
    background-color: var(--theme-primary-container);
    border: 1px solid var(--theme-primary-container)
}
.subject{
    margin-top: 0.5rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mails_list_header{
    display: flex;
    padding-bottom: 1rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.mail-viewer{
    border-radius: 0.7rem;
    margin-left: 1rem;
    overflow-y: scroll;
    padding: 2rem;
    padding-top: 0px;
    border: 1px solid #f2f2f2;
    background-color: #fff;
    height: 100%;
    width: 100%;
    overflow: auto;
}

.submit_message{
    display: flex;
    background: var(--theme-primary-container);
    justify-content: space-between; 
    align-items: center;
    border-radius: 10px;
    font-size: 12px;
    padding: 10px 10px 10px 20px
 }

 .mail-placeholder{
    display: flex !important;
    margin-left: 1rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: 0.8rem;
    text-align: center;
    flex-direction: column;
    background-color: var(--theme-surface-color);
    
 }

@media (max-width: 1000px) {
    .mail-viewer{
        position: fixed;
        margin-left: 0rem;
        box-shadow: 0rem 0rem 0.6rem rgba(0,0,0,0.2);
        z-index: 10;
        bottom: 0;
        left: 0;
        padding: 2rem;
        padding-top: 0px;
        border: 0px;
        background-color: #fff;
        border-radius: 2rem 2rem 0rem 0rem;
        height: 90% !important;
        width: 100% !important;
        overflow-y: scroll;
    }
    .mail-placeholder{
        display: none !important;
    }
}
.invoice-taxes{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.invoice-taxes .wrap{
    display: flex; 
    padding: 0.5rem 0px;
    border-top: 1px solid #ddd
}

.invoice-taxes .wrap div{
    width: 100px
}

.invoice-taxes .wrap > :first-child{
    text-align: left;
}

.invoice-taxes .wrap > :last-child{
    text-align: right;
}

table{
    border-spacing: 0 !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

th, td{
    font-size: 0.8rem !important;
}

th{
    height: auto !important;
    padding: 0.5rem 0rem !important;
    border-bottom: 1px solid #000;
}

td{
    padding: 0.5rem 0rem !important;
}

td:last-child {
    text-align: end;
}
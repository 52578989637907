@font-face {
    font-family: Dice;
    font-weight: 900;
    src: url('https://static.eka.io/fonts/apercu/bold.otf');
}

@font-face {
    font-family: Dice;
    font-weight: 700;
    src: url('https://static.eka.io/fonts/apercu/bold.otf');
}

@font-face {
    font-family: Dice;
    font-weight: 800;
    src: url('https://static.eka.io/fonts/apercu/bold.otf');
}

@font-face {
    font-family: Dice;
    font-weight: 300;
    src: url('https://static.eka.io/fonts/light.otf');
}

@font-face {
    font-family: Dice;
    font-weight: 400;
    src: url('https://static.eka.io/fonts/apercu/regular.otf');
}
@font-face {
    font-family: Dice;
    font-weight: 500;
    src: url('https://static.eka.io/fonts/apercu/regular.otf');
}
@font-face {
    font-family: Dice;
    font-weight: 600;
    src: url('https://static.eka.io/fonts/apercu/bold.otf');
}

.yum{
    background: rgb(209, 231, 209);
}

.yum:hover{
    background: rgb(163, 208, 162);
}

.inline{
    display: inline;
}

.pdx{
    padding: 4px;
}


.text-color{
    color: var(--theme-text-color) !important;
}

.primary-color{
    color: var(--theme-primary-color) !important;
}

.file-card{
    background-color: #fff;
    border: 1px solid var(--theme-border-color);
    border-radius: 0.75rem;
    margin: 0.25rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    width: 240px;
    height: 5rem;
    font-size: 0.8rem;
    cursor: pointer;
    transition: ease all 0.2s;
}

.file-card:hover{
    background-color: var(--theme-primary-container);
    border-color: var(--theme-primary-container);
}

@media (max-width: 576px) {
    .file-card{
        width: 100% !important;
    }
}
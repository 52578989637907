.shimmer{
    width: 0;
    animation: fullView 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.wave{
    animation : shimmer 2s infinite linear;
    background: linear-gradient(to right, #fefefe 4%, #f1f1f1 25%, #fefefe 36%);
    background-size: 1000px 100%;
}


@keyframes fullView {
    100% {
        width: 100%;
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}



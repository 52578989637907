.inline-tabs{
    display: inline-flex;
    border-radius: 0.25rem;
    overflow: hidden;
    background: var(--theme-variant-color);
    padding: 3px;
}

.inline-tabs .tab{
    flex-grow: 1;
    cursor: pointer;
    text-align: center;
    padding:0.5rem 1.25rem;
    font-size: 1rem;

}
.inline-tabs .tab.active{
    box-shadow: 0 0.2rem 0.6rem rgba(0,0,0,0.2);
    border-radius: 0.3rem;
    background: white;
    font-weight: 600;
}

.progress-indicator{
    display: flex;
}

.progress-indicator .level{
    width:1rem;
    height:0.3rem;
    background: var(--theme-variant-color);
    border-radius:1rem;
    margin-right:0.25rem;
    transition: ease all 0.2s;
}

.progress-indicator .level-active{
    width:3rem;
    height:0.5rem;
    background: #000;
    border-radius:1rem;
    margin-right:0.25rem;
    transition: ease all 0.2s;
}


.im-loader {
    height: 4px;
    background-color: var(--theme-variant-color);
    width: 100%;
    overflow: hidden;
}

.im-loader-value {
    width: 100%;
    height: 100%;
    background-color: var(--theme-on-variant-color);
    animation: indeterminateAnimation 3.5s infinite linear;
    transform-origin: 0 50%;
}

@keyframes indeterminateAnimation {
    0% {
        transform:  translateX(0) scaleX(0.075);
    }
    15% {
        transform:  translateX(100%) scaleX(0.075);
    }
    30% {
        transform:  translateX(0) scaleX(0.075);
    }
    50% {
        transform:  translateX(100%) scaleX(0.075);
    }
    65% {
        transform:  translateX(0) scaleX(0.075);
    }
    85% {
        transform:  translateX(100%) scaleX(0.075);
    }
    100% {
        transform:  translateX(0) scaleX(0.075);
    }
}

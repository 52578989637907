html{
    font-size: 16px!important;
}

body{
    --font-family : -apple-system,'proxima-nova',"Segoe UI",'Roboto', sans-serif !important;
}
  
  @media (max-width: 576px){
    html{
      font-size: 14px !important;
    }
}

#wrapper{
    position: relative !important;
    padding: 1rem 4rem !important;
}

@media (max-width: 768px) {
    #wrapper{
        width: 100% !important;
        padding: 1rem 1rem !important;
        flex-shrink: 0 !important;
    }
}
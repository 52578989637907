#sidebar-top .icon{
    padding: .5rem .7rem;
    padding-bottom: .4rem;
    border: 1px solid var(--theme-border-color);
    border-radius: var(--size-large);
    cursor: pointer;
    transition: ease all 0.2s;
}

#sidebar-top .icon svg{
    margin-top: 2px;
}

#sidebar-top .icon:hover{
    background: var(--theme-link-container);
    border: 1px solid var(--theme-link-container);
}

.status-chip{
    font-weight: bold;
    padding: 0.35rem 0.7rem;
    border-radius: 5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
    max-width: 140px;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.success{
    background-color: rgb(240 253 244);;
    color: rgb(21 128 61);
    border: 1px solid rgb(22 163 74 / 0.2);
}

.success-text{
    color: rgb(21 128 61);
}

.failed{
    background-color: rgb(253 232 232);;
    color: rgb(155 28 28);
    border: 1px solid rgb(155 28 28 / 0.2);
}

.failed-text{
    color: rgb(155 28 28);
}

.status-chip.preview{
    background-color: rgb(243 244 246);;
    color: rgb(31 41 55);
    border: 1px solid rgb(31 41 55 / 0.2);
}

.preview-text{
    color: rgb(31 41 55);
}

.linear{
    font-size: 12px;
}

.pending{
    background-color: rgb(229 237 255);;
    color: rgb(66 56 157);
    border: 1px solid rgb(66 56 157 / 0.2);
}

.pending-text{
    color: rgb(66 56 157);
}

.cancelled{
    background-color: rgb(237 235 254);;
    color: rgb(85 33 181);
    border: 1px solid rgb(85 33 181 / 0.2);
}
.cancelled-text{
    color: rgb(85 33 181);
}

.success{
    background-color: rgb(240 253 244);;
    color: rgb(21 128 61);
    border: 1px solid rgb(22 163 74 / 0.2);
}

.disabled{
    background-color: var(--theme-border-color);
}

#wrapper{
    position: relative;
    padding: 70px;
}
#non-sticky .ql-toolbar.ql-snow{
    position: relative;
    top: 0;
}

#wrapper-nav {
    padding: 1rem 4rem 3rem 4rem !important;
    position: relative!important;
}

@media (max-width: 768px) {
    #wrapper-nav {
        flex-shrink: 0!important;
        padding: 1rem!important;
        width: 100%!important;
    }
    #wrapper{
        width: 100%;
        flex-shrink: 0;
        padding: 48px;
    }
    #page{
        margin-left: -20px;
        margin-right: -20px;
    }
}
